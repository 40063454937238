// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import html2canvas from 'packs/html2image';
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var Trix = require("trix")
require("richtext")
require("@rails/actiontext")
require("packs/html2image")
require("packs/FileSaver")
require("jquery")

export {
    html2canvas
}


/* what the newly created button does */
/*Trix.config.textAttributes.red = {
    style: { color: "rgb(255, 0, 0)" },
    parser: function(element) {
        return element.style.color === "rgb(255, 0, 0)"
    },
    inheritable: true
}

/* insert the button visual in the default toolbar */
/*addEventListener("trix-initialize", function(event) {
    var buttonHTML = '<button type="button" class="trix-button trix-button-red" style="color:#ff3838;font-weight: bold;" data-trix-attribute="red">▉</button>'

    event.target.toolbarElement.
    querySelector(".trix-button-group").
    insertAdjacentHTML("beforeend", buttonHTML)
})*/